$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
